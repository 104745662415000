import React from "react";
import { UNTITLED } from "../graphql";
import Layout from "../components/Layout";
import Head from "../components/Head";
import Monogram from "../components/Monogram";
import Header from "../components/Header";
import MarkdownContent from "../components/MarkdownContent";
import { ContentPageContext } from "../util/page-types";

export type ContentPageProps = Readonly<{
  data: null;
  pageContext: ContentPageContext;
}>;

const ContentPage: React.FC<ContentPageProps> = ({ pageContext }) => {
  const {
    frontmatter: { title = UNTITLED, description, image },
    body,
  } = pageContext.page.childMdx;
  return (
    <Layout>
      <Head title={title} description={description} image={image} />
      <Header title={title} />
      <MarkdownContent html={body} />
      <Monogram footer={true} />
    </Layout>
  );
};

export default ContentPage;
